import './style.css';

import { useEffect, useRef, useState } from 'react';

import cv from '@techstark/opencv-js';
import diagnoseImage from '../assets/Diagnose.png';

window.cv = cv;

const FACING_MODE_ENVIRONMENT = 'environment';

const DetectLed = () => {
  const [imgUrl, setImgUrl] = useState(null);
  const [capture, setCapture] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [fps, setFps] = useState('30');
  const [diagnoseLeds, setDiagnoseLeds] = useState({});

  const videoRef = useRef(null);
  const debugImageRef = useRef(null);

  useEffect(() => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      startCapture();
    } else {
      alert('Camera not available!');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let handle;
    let frameCnt = 0;
    let frameTime = 0;
    const nextTick = () => {
      handle = requestAnimationFrame(async (time) => {
        captureFrame()
          .then((result) => {
            if (time - frameTime > 1000) {
              setFps(`${frameCnt} fps, ${result}`);
              frameCnt = 0;
              frameTime = time;
            } else frameCnt++;
            nextTick();
          })
          .catch((err) => {
            console.error(err);
            nextTick();
          });
      });
    };
    nextTick();
    return () => {
      console.log('cleanup');
      cancelAnimationFrame(handle);
    };
    // eslint-disable-next-line
  }, [capture]);

  function startCapture() {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode,
          frameRate: { ideal: 30, max: 30 },
        },
        audio: false,
      })
      .then(function (stream) {
        let streamSettings = stream.getVideoTracks()[0].getSettings();
        videoRef.current.width = streamSettings.width;
        videoRef.current.height = streamSettings.height;
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        console.log(videoRef.current);
        setCapture(new cv.VideoCapture(videoRef.current));
      })
      .catch(function (err) {
        if (err.name === 'OverconstrainedError' && err.constraint === 'facingMode') {
          if (facingMode === 'environment' && !capture) {
            setFacingMode();
          }
        } else {
          console.error(err);
          setErrorMessage('Camera access not available: ' + err.message);
        }
      });
  }

  async function captureFrame(src) {
    if (!src && (!capture || !videoRef.current)) return;

    return new Promise((resolve) => {
      let img;
      if (src) img = cv.imread(src);
      else {
        img = new cv.Mat(videoRef.current.height, videoRef.current.width, cv.CV_8UC4);
        capture.read(img);
      }
      cv.imshow(debugImageRef.current, img);
      const resolution = `${img.cols} x ${img.rows}`;
      try {
        const stick = extractStick(img);
        if (stick) {
          const leds = detectLeds(stick);
          if (leds) setDiagnoseLeds(leds);
        }
        resolve(resolution);
      } catch (err) {
        //console.error(err);
        setErrorMessage(err.message);
        resolve(`${resolution}, ${err.message}`);
      }
    });
  }

  function extractStick(image) {
    const original = image;
    setErrorMessage();

    /*
    // find all red parts
    let mask = new cv.Mat();
    const lower_red = new cv.Mat(img.rows, img.cols, img.type(), [0, 50, 0, 0]);
    const upper_red = new cv.Mat(img.rows, img.cols, img.type(), [255, 255, 220, 255]);
    cv.inRange(img, lower_red, upper_red, mask);

    // mask out all red parts
    let imgWithoutRed = new cv.Mat();
    cv.bitwise_and(img, img, imgWithoutRed, mask);
    
    lower_red.delete();
    upper_red.delete();
    mask.delete();
    */

    /*
    // convert to HSV
    const hsvImage = new cv.Mat();
    cv.cvtColor(imgWithoutRed, hsvImage, cv.COLOR_BGR2HSV);
    imgWithoutRed.delete();

    // find the yellow borders of the stick
    mask = new cv.Mat();
    const lower_yellow = new cv.Mat(hsvImage.rows, hsvImage.cols, hsvImage.type(), [50, 50, 50, 0]);
    const upper_yellow = new cv.Mat(hsvImage.rows, hsvImage.cols, hsvImage.type(), [255, 255, 255, 255]);
    cv.inRange(img, lower_yellow, upper_yellow, mask);
    hsvImage.delete();
    lower_yellow.delete();
    upper_yellow.delete();
    cv.imshow(debugImageRef.current, mask);
    */

    // find the yellow borders of the stick
    const mask = new cv.Mat();
    const lower_yellow = new cv.Mat(original.rows, original.cols, original.type(), [100, 50, 0, 0]);
    const upper_yellow = new cv.Mat(original.rows, original.cols, original.type(), [255, 200, 25, 255]);
    cv.inRange(original, lower_yellow, upper_yellow, mask);
    cv.imshow(debugImageRef.current, mask);

    //find the contours of the stick
    const contours = new cv.MatVector();
    const hierarchy = new cv.Mat();
    cv.findContours(mask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

    // sort the contours by their size
    let sortableContours = [];
    for (let i = 0; i < contours.size(); i++) {
      let cnt = contours.get(i);
      // only use contours with the right geometry
      const rect = cv.minAreaRect(cnt);
      const w = rect.size.width < rect.size.height ? rect.size.width : rect.size.height;
      const h = rect.size.width > rect.size.height ? rect.size.width : rect.size.height;
      if (h < 100 || h / w < 10) continue;

      let area = cv.contourArea(cnt, false);
      let perim = cv.arcLength(cnt, false);
      sortableContours.push({ areaSize: area, perimiterSize: perim, contour: cnt });
    }

    sortableContours = sortableContours
      .sort((item1, item2) => {
        return item1.areaSize > item2.areaSize ? -1 : item1.areaSize < item2.areaSize ? 1 : 0;
      })
      .slice(0, 2);

    mask.delete();
    lower_yellow.delete();
    upper_yellow.delete();
    contours.delete();
    hierarchy.delete();

    // need two side contours
    if (sortableContours.length < 2) throw new Error("Didn't find stick sides");

    // get the surrounding rotated rectangle of the 2 largest boxes
    const rotatedRect1 = cv.minAreaRect(sortableContours[0].contour);
    const rotatedRect2 = cv.minAreaRect(sortableContours[1].contour);

    // stop if the angle of the both side contours are too different
    if (Math.abs(rotatedRect1.angle - rotatedRect2.angle) > 2)
      throw new Error(`Angle of stick is too different: ${rotatedRect1.angle.toFixed(2)} : ${rotatedRect2.angle.toFixed(2)}`);

    // stop if the dimension of the both side contours are too different
    if (Math.abs(rotatedRect1.size.width - rotatedRect2.size.width) > 50) throw new Error('Width of stick sides is too different');
    if (Math.abs(rotatedRect1.size.height - rotatedRect2.size.height) > 50) throw new Error('Height of stick sides is too different');

    let box1 = cv.RotatedRect.points(rotatedRect1);
    let box2 = cv.RotatedRect.points(rotatedRect2);
    // sort the boxes from left to right
    if (box1[0].x > box2[0].x) {
      let tmp = box1;
      box1 = box2;
      box2 = tmp;
    }

    // adjust the rotation angle
    let angle = (rotatedRect1.angle + rotatedRect2.angle) / 2;
    if (rotatedRect1.size.width > rotatedRect1.size.height) angle = angle > 45 ? angle - 90 : angle - 270;

    // rotate the image so that the stick is upright
    let rotatedImage = new cv.Mat();
    let rotatedSize = original.cols > original.rows ? new cv.Size(original.rows, original.cols) : new cv.Size(original.cols, original.rows);
    let rotationCenter = new cv.Point(
      (rotatedRect1.center.x + rotatedRect2.center.x) / 2,
      (rotatedRect1.center.y + rotatedRect2.center.y) / 2
    );
    let M = cv.getRotationMatrix2D(rotationCenter, angle, 1);
    cv.warpAffine(original, rotatedImage, M, rotatedSize, cv.INTER_LINEAR, cv.BORDER_CONSTANT, new cv.Scalar());

    // copy the stick to a new image
    const w = Math.min(
      rotatedImage.cols,
      Math.hypot(box2[0].x - box1[0].x, box2[0].y - box1[0].y) - Math.min(rotatedRect1.size.width, rotatedRect1.size.height)
    );
    const h = Math.min(rotatedImage.rows, Math.max(rotatedRect1.size.width, rotatedRect1.size.height));
    let roiImage = new cv.Mat();
    let rect = new cv.Rect(Math.max(0, rotationCenter.x - w / 2), Math.max(0, rotationCenter.y - h / 2), w, h);
    roiImage = rotatedImage.roi(rect);
    cv.imshow(debugImageRef.current, roiImage);

    rotatedImage.delete();
    original.delete();
    return roiImage;
  }

  function detectLeds(stickImage) {
    const stickW = stickImage.cols;
    const statusLedW = Math.floor(stickW / 4);

    let checkLeds = undefined;
    let margin = 1;
    let wideLeds = [];
    const leds = {};

    while (!checkLeds && margin > 0.5) {
      const leds = findLeds(stickImage, margin);

      // need to find at least 'wide' ossd and field leds
      wideLeds = leds.filter((led) => led.w > statusLedW);
      //console.log(JSON.parse(JSON.stringify(leds)));

      if (wideLeds.length === 2) checkLeds = leds;
      else margin -= 0.05; // decrease thresshold margin to find larger contours
    }
    if (!checkLeds) {
      stickImage.delete();
      if (wideLeds.length === 1) {
        wideLeds[0].type = 'OSSD';
        wideLeds[0].x = 0;
        wideLeds[0].w = stickW - 1;
        wideLeds[0].h = 4;
        leds['ossd'] = wideLeds[0].color;
        return leds;
      }
      throw new Error("Didn't find ossd and field led");
    }
    //console.log(margin, JSON.parse(JSON.stringify(checkLeds)));

    // find ossd and field leds
    let fieldY = stickImage.rows;
    let ossdY = 0;

    checkLeds.forEach((led) => {
      if (led.y < fieldY) fieldY = led.y;
      if (led.y > ossdY) ossdY = led.y;
    });
    //console.log(ossdY, fieldY);

    const ossdLed = checkLeds.filter((led) => led.y === ossdY).pop();
    ossdLed.type = 'OSSD';
    ossdLed.x = 0;
    ossdLed.w = stickW - 1;
    ossdLed.h = 4;
    leds['ossd'] = ossdLed.color;

    const fieldLed = checkLeds.filter((led) => led.y === fieldY).pop();
    fieldLed.type = 'FIELD';
    fieldLed.x = 0;
    fieldLed.w = stickW - 1;
    fieldLed.h = 4;
    leds['field'] = fieldLed.color;

    const upperStatusY = Math.round(fieldY - Math.round((fieldY - ossdY) / 4));
    const lowerStatusY = Math.round(ossdY + Math.round((fieldY - ossdY) / 4));

    const ledImage = stickImage; //new cv.Mat(stickImage.rows, stickImage.cols, stickImage.type(), [255, 255, 255, 255]);
    //stickImage.delete();

    //cv.rectangle(ledImage, new cv.Point(0, 0), new cv.Point(ledImage.cols - 1, ledImage.rows - 1), new cv.Scalar(0, 0, 0, 255), 1);
    cv.rectangle(
      ledImage,
      new cv.Point(0, lowerStatusY - 10),
      new cv.Point(ledImage.cols - 1, lowerStatusY + 10),
      new cv.Scalar(255, 255, 255, 200),
      1
    );
    cv.rectangle(
      ledImage,
      new cv.Point(0, upperStatusY - 10),
      new cv.Point(ledImage.cols - 1, upperStatusY + 10),
      new cv.Scalar(255, 255, 255, 200),
      1
    );
    //cv.rectangle(ledImage, new cv.Point(0, ossdY), new cv.Point(ledImage.cols - 1, ossdY), new cv.Scalar(0, 0, 0, 255), 1);
    //cv.rectangle(ledImage, new cv.Point(0, fieldY), new cv.Point(ledImage.cols - 1, fieldY), new cv.Scalar(0, 0, 0, 255), 1);

    cv.rectangle(ledImage, new cv.Point(stickW / 4, ossdY - 3), new cv.Point(stickW / 4, fieldY + 4), new cv.Scalar(255, 255, 255, 100), 1);
    cv.rectangle(ledImage, new cv.Point(stickW / 2, ossdY - 3), new cv.Point(stickW / 2, fieldY + 4), new cv.Scalar(255, 255, 255, 100), 1);
    cv.rectangle(
      ledImage,
      new cv.Point((stickW * 3) / 4, ossdY - 3),
      new cv.Point((stickW * 3) / 4, fieldY + 4),
      new cv.Scalar(255, 255, 255, 100),
      1
    );

    const statusLeds = checkLeds.filter((led) => led.y < ossdY && led.y > fieldY && led.w <= statusLedW && led.h <= statusLedW);
    //console.log(statusLedW, JSON.parse(JSON.stringify(statusLeds)));

    for (let i = 0; i < statusLeds.length; i++) {
      const led = statusLeds[i];

      // draw the originally detected led
      cv.rectangle(
        ledImage,
        new cv.Point(led.x - led.w / 2, led.y - led.h / 2),
        new cv.Point(led.x + led.w, led.y + led.h),
        led.drawColor,
        1
      );

      // assign the leds to the columns
      let col = 0;
      if (led.x < statusLedW) {
        col = 1;
      } else if (led.x <= statusLedW * 2) {
        col = 2;
      } else if (led.x <= statusLedW * 3) {
        col = 3;
      } else if (led.x > statusLedW * 3) {
        col = 4;
      }

      // unify led appearance
      led.type = 'STATUS';
      led.x = (col - 1) * statusLedW + statusLedW / 2;
      led.w = statusLedW - 3;
      led.h = statusLedW - 3;

      // asign to led to the status led
      if (Math.abs(led.y - lowerStatusY) < 10) {
        if (leds[`status${col}`]) {
          ledImage.delete();
          throw new Error(`Unclear status of led Status${col}`);
        }
        led.y = lowerStatusY;
        leds[`status${col}`] = led.color;
      } else if (Math.abs(led.y - upperStatusY) < 10) {
        if (leds[`Status${col + 4}`]) {
          ledImage.delete();
          throw new Error(`Unclear status of led Status${col + 4}`);
        }
        led.y = upperStatusY;
        leds[`status${col + 4}`] = led.color;
      }
    }

    // draw the leds
    checkLeds.forEach((led) => {
      cv.rectangle(
        ledImage,
        new cv.Point(Math.max(0, led.x - led.w / 2), led.y - led.h / 2),
        new cv.Point(led.x + led.w, led.y + led.h),
        led.drawColor,
        1
      );
    });
    cv.imshow(debugImageRef.current, ledImage);
    ledImage.delete();

    return leds;
  }

  function findLeds(stickImage, margin) {
    const greyImage = new cv.Mat();
    cv.cvtColor(stickImage, greyImage, cv.COLOR_BGR2GRAY);

    // find the maximum value of brightness and use only the brightest spots to find the contours
    const result = cv.minMaxLoc(greyImage, greyImage);
    const contours = new cv.MatVector();
    const hierarchy = new cv.Mat();
    const thresh = result.maxVal * margin;
    let threshImage = new cv.Mat();
    cv.threshold(greyImage, threshImage, thresh, 255, cv.THRESH_BINARY);
    cv.findContours(threshImage, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
    //console.log(margin + ': ' + contours.size());
    greyImage.delete();
    threshImage.delete();

    // convert original image to bgra so that color codes can be read
    /*const hlsImage = new cv.Mat();
    cv.cvtColor(stickImage, hlsImage, cv.COLOR_BGR2HLS);
    const cols = bgraImage.cols;
    const channels = bgraImage.channels();*/

    // convert original image to bgra so that color codes can be read
    const bgraImage = new cv.Mat();
    cv.cvtColor(stickImage, bgraImage, cv.COLOR_BGR2BGRA);
    const cols = bgraImage.cols;
    const channels = bgraImage.channels();

    const checkLeds = [];

    // iterate all contours and find the color
    for (let i = 0; i < contours.size(); i++) {
      let cnt = contours.get(i);

      // get the position and size of the enclosing rect
      const rect = cv.minAreaRect(cnt);
      const x = Math.round(rect.center.x);
      const y = Math.round(rect.center.y);
      const w = rect.angle < 45 ? rect.size.width : rect.size.height;
      const h = rect.angle < 45 ? rect.size.height : rect.size.width;
      if (h > cols / 4) continue;

      /*
      // detecting color by hls is problematic because the center of the led always appears as white
      const hue = bgraImage.data[y * cols * channels + x * channels];
      const lgt = bgraImage.data[y * cols * channels + x * channels + 1];
      if (lgt > 0.8) checkLeds.push({ x, y: rect.center.y, w, h, hue, lgt, color: 'white', drawColor: new cv.Scalar(255, 255, 255, 255) });
      else if (hue < 30) checkLeds.push({ x, y: rect.center.y, w, h, hue, color: 'red', drawColor: new cv.Scalar(255, 0, 0, 255) });
      else if (hue < 90) checkLeds.push({ x, y: rect.center.y, w, h, hue, color: 'yellow', drawColor: new cv.Scalar(255, 255, 0, 255) });
      else if (hue < 150) checkLeds.push({ x, y: rect.center.y, w, h, hue, color: 'green', drawColor: new cv.Scalar(0, 255, 0, 255) });
      else if (hue < 270) checkLeds.push({ x, y: rect.center.y, w, h, hue, color: 'blue', drawColor: new cv.Scalar(0, 0, 255, 255) });
      */

      // detect color by rgb value
      // move from the (very bright) center to the outside until a specific color can be detected
      // may not be possible to detect white this way..
      let R = 255;
      let G = 255;
      let B = 255;
      let offsetY = 0;

      while (R > 100 && G > 100 && B > 100 && offsetY < 10) {
        offsetY++;
        R = bgraImage.data[(y - offsetY) * cols * channels + x * channels];
        G = bgraImage.data[(y - offsetY) * cols * channels + x * channels + 1];
        B = bgraImage.data[(y - offsetY) * cols * channels + x * channels + 2];
      }

      // find the dominating color
      // TODO: detect yellow and white
      if (R / G > 1 && R / G < 1.3) {
        checkLeds.push({ x, y, w, h, R, G, B, color: 'yellow', drawColor: new cv.Scalar(255, 255, 0, 255) });
      } else if (R > G && R > B) {
        checkLeds.push({ x, y, w, h, R, G, B, color: 'red', drawColor: new cv.Scalar(255, 0, 0, 255) });
      } else if (G > R && G > B) {
        checkLeds.push({ x, y, w, h, R, G, B, color: 'green', drawColor: new cv.Scalar(0, 255, 0, 255) });
      } else if (B > R && B > R) {
        checkLeds.push({ x, y, w, h, R, G, B, color: 'blue', drawColor: new cv.Scalar(0, 0, 255, 255) });
      }
    }

    contours.delete();
    hierarchy.delete();

    //hlsImage.delete();
    bgraImage.delete();

    return checkLeds;
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ background: '#f00', margin: '10px', justifyContent: 'center' }}>
        <input
          type='file'
          name='file'
          accept='image/*'
          onChange={(e) => {
            if (e.target.files[0]) {
              setImgUrl(URL.createObjectURL(e.target.files[0]));
            }
          }}
        />
      </div>
      {!imgUrl && <video ref={videoRef} id='videoInput' onClick={() => captureFrame()} />}
      <div>
        {fps} FPS, {errorMessage}
      </div>

      <div className='images-container'>
        {imgUrl && (
          <div className='image-card'>
            <img
              alt='Original input'
              src={imgUrl}
              onLoad={(e) => {
                captureFrame(e.target);
              }}
            />
          </div>
        )}

        <div className='diagnose'>
          <div className='diagnose-bg'>
            <img src={diagnoseImage} alt='Diagnose' />
          </div>
          <div className='diagnose-leds'>
            {Object.keys(diagnoseLeds).map((name) => (
              <div key={name} className={`diagnose-led ${name}  ${diagnoseLeds[name]}`} />
            ))}
          </div>
        </div>
        <div className='image-card'>
          <canvas ref={debugImageRef} />
        </div>
      </div>
    </div>
  );
};

export default DetectLed;
